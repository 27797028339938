// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-r-2-d-2-faq-js": () => import("./../../../src/pages/r2d2/faq.js" /* webpackChunkName: "component---src-pages-r-2-d-2-faq-js" */),
  "component---src-pages-r-2-d-2-index-js": () => import("./../../../src/pages/r2d2/index.js" /* webpackChunkName: "component---src-pages-r-2-d-2-index-js" */),
  "component---src-pages-r-2-d-2-instructions-js": () => import("./../../../src/pages/r2d2/instructions.js" /* webpackChunkName: "component---src-pages-r-2-d-2-instructions-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-use-of-website-js": () => import("./../../../src/pages/use-of-website.js" /* webpackChunkName: "component---src-pages-use-of-website-js" */)
}

