import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

const Redirect = ({ to, includeQuery = false, replace = false }) => {
  useEffect(() => {
    if (!to) {
      return;
    }

    let url = to;
    if (includeQuery) {
      const queryParams = window.location.search || '';
      url = `${url}${queryParams}`;
    }
    navigate(url, { replace });
  }, [to]);

  return <></>;
};

export default Redirect;
