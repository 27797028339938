import CartStore from './CartStore';
import ProductStore from './ProductStore';
import UserStore from './UserStore';

const rootStore = {};

export default function createStore(apiClient) {
  rootStore.cartStore = new CartStore(rootStore, apiClient);
  rootStore.productStore = new ProductStore(rootStore, apiClient);
  rootStore.userStore = new UserStore(rootStore, apiClient);

  async function initStore() {
    await rootStore.userStore.init();
    await rootStore.cartStore.init();
    await rootStore.productStore.init();
  }

  return {
    initStore,
    rootStore
  };
}
