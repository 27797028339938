import { action, observable, reaction } from 'mobx';
import BaseStore from './BaseStore';

export default class UserStore extends BaseStore {
  @observable isAuthenticated = false;

  @observable user = {};

  @observable authHeaders = {};

  @action
  async init() {
    reaction(
      () => this.user,
      user => {
        if (!user) {
          window.localStorage.removeItem('user');
        } else {
          window.localStorage.setItem('user', JSON.stringify(user));
        }
      }
    );

    reaction(
      () => this.authHeaders,
      headers => {
        if (!headers || !Object.keys(headers).length) {
          window.localStorage.removeItem('userheader');
        } else {
          this.apiClient.setAuthHeaders(
            headers.uid,
            headers.token,
            headers.client
          );
          window.localStorage.setItem('userheader', JSON.stringify(headers));
        }
      }
    );

    this.apiClient.addResponse403Listener(this.clearUser);

    const authHeaders = JSON.parse(window.localStorage.getItem('userheader'));
    const { uid, token, client } = authHeaders || {};
    if (!uid || !token || !client) {
      this.clearUser();
      return;
    }
    this.setAuthHeaders(uid, token, client);
    await this.getUser();
  }

  @action
  async getUser() {
    try {
      const { data } = await this.apiClient.sendRequest(
        'get',
        '/api/v1/users/get_current_user'
      );
      this.user = data.response;
      this.isAuthenticated = true;
    } catch (err) {
      if (
        err.response &&
        (err.response.status === 401 || err.response.status === 403)
      ) {
        this.clearUser();
      } else {
        throw err;
      }
    }
  }

  @action
  async login(email, password) {
    try {
      const resp = await this.apiClient.sendRequest('post', '/auth/sign_in', {
        body: {
          uid: email,
          password,
          provider: 'email'
        }
      });

      this.setAuthHeaders(
        resp.headers.uid,
        resp.headers['access-token'],
        resp.headers.client
      );
      this.user = resp.data.response;
      this.isAuthenticated = true;
    } catch (err) {
      if (
        err.response &&
        (err.response.status === 401 || err.response.status === 403)
      ) {
        this.clearUser();
      } else {
        throw err;
      }
    }
  }

  @action
  setAuthHeaders(uid, token, client) {
    this.authHeaders = { uid, token, client };
    this.apiClient.setAuthHeaders(uid, token, client);
  }

  @action
  clearUser = () => {
    this.isAuthenticated = false;
    this.user = null;
    this.authHeaders = null;
  };
}
