import { shouldPolyfill } from '@formatjs/intl-numberformat/should-polyfill';
import 'modern-normalize/modern-normalize.css';
import './src/styles/global.scss';
import './src/fonts/proximanova/proximanova.css';

import { getLocaleFromPath } from './src/util/pathUtil';
import wrapPageWithProvider from './src/wrapPageWithProvider';

async function polyfillLocale(locale) {
  if (shouldPolyfill()) {
    await import('@formatjs/intl-numberformat/polyfill');
  }

  if (Intl.NumberFormat.polyfilled) {
    if (locale === 'zh-cn') {
      await import('@formatjs/intl-numberformat/locale-data/zh-Hans');
    } else if (/^zh/.test(locale)) {
      await import('@formatjs/intl-numberformat/locale-data/zh-Hant');
    } else {
      await import('@formatjs/intl-numberformat/locale-data/en');
    }
  }
}

export const onClientEntry = () => {
  polyfillLocale();

  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    /* eslint-disable-next-line no-unused-expressions */
    import(`intersection-observer`);
  }

  // Force fetch a no-op service worker to replace one in cache
  fetch('/service-worker.js').then(() => {
    // Remove any previously registered service workers from BTMF
    navigator.serviceWorker.getRegistrations().then(registrations => {
      registrations.forEach(r => r.unregister());
    });
  });
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  const prevLocale = prevLocation && getLocaleFromPath(prevLocation.pathname);
  const currLocale = getLocaleFromPath(location.pathname);
  if (prevLocale !== currLocale) {
    polyfillLocale(currLocale);
  }
};

export const wrapPageElement = wrapPageWithProvider;
