import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';

const I18nProvider = ({ children, locale, defaultLocale, messages }) => {
  useEffect(() => {
    window.localStorage.setItem('locale', locale);

    let btmfLocale;
    if (locale === 'zh-hk') {
      btmfLocale = 'hk';
    } else if (locale === 'zh-tw') {
      btmfLocale = 'tw';
    } else if (locale === 'zh-cn') {
      btmfLocale = 'zh';
    } else {
      btmfLocale = 'en';
    }
    window.localStorage.setItem('lang', btmfLocale);
  }, [locale]);

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={defaultLocale}
      messages={messages}
    >
      {children}
    </IntlProvider>
  );
};

export default I18nProvider;
