import React, { createContext, useContext, useEffect } from 'react';

const StoreContext = createContext({});

export const useStore = () => useContext(StoreContext);

const StoreProvider = ({ currency, initStore, store, children }) => {
  useEffect(() => {
    initStore();
  }, []);

  useEffect(() => {
    store.cartStore.setCurrency(currency);
  }, [store, currency]);

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export default StoreProvider;
