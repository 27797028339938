import React from 'react';
import createStore from './store';
import ApiClient from './api/ApiClient';
import ApiProvider from './components/ApiProvider';
import I18nProvider from './components/I18nProvider';
import StoreProvider from './components/StoreProvider';
import LocaleForceRedirect from './components/LocaleForceRedirect';
import getLocalesByRegion from '../build-utils/getLocalesByRegion';
import ALL_LOCALES from '../locales.json';

const locales = getLocalesByRegion(ALL_LOCALES, process.env.REGION);

const apiClient = new ApiClient(process.env.API_URL);
const { initStore, rootStore } = createStore(apiClient);

export default function wrapPageWithProvider({ element, props }) {
  const { pageContext } = props;
  const { intl } = pageContext;
  const { locale, messages, forceRedirect, originalPath } = intl;

  const defaultLocale = locales[0];
  const { currency } = ALL_LOCALES[locale];

  if (forceRedirect) {
    return (
      <LocaleForceRedirect
        fallbackLocale={defaultLocale}
        nonLocalePath={originalPath}
      />
    );
  }

  return (
    <I18nProvider
      locale={locale}
      defaultLocale={defaultLocale}
      messages={messages}
    >
      <ApiProvider apiClient={apiClient}>
        <StoreProvider
          currency={currency}
          initStore={initStore}
          store={rootStore}
        >
          {element}
        </StoreProvider>
      </ApiProvider>
    </I18nProvider>
  );
}
