import { v4 as uuidV4 } from 'uuid';
import { action, autorun, computed, observable, reaction } from 'mobx';
import BaseStore from './BaseStore';

export default class CartStore extends BaseStore {
  @observable items = [];

  @observable cartKey = '';

  @computed get numItems() {
    return this.items.reduce((sum, item) => sum + item.amount, 0);
  }

  @observable currency;

  @observable isCartErrorModalOpen = false;

  @action
  async init() {
    const existingCartKey = window.localStorage.getItem('cartKey');
    if (existingCartKey) {
      this.cartKey = existingCartKey;
    }

    autorun(() => {
      if (this.cartKey) {
        window.localStorage.setItem('cartKey', this.cartKey);
      } else {
        window.localStorage.removeItem('cartKey');
      }
    });

    reaction(
      () => this.cartKey,
      async () => {
        await this.getCartItems();
      }
    );

    reaction(
      () => this.rootStore.userStore.user,
      user => {
        if (user && user.shopping_cart_key) {
          this.cartKey = user.shopping_cart_key;
        }
      },
      {
        fireImmediately: true
      }
    );

    if (!existingCartKey && !this.rootStore.userStore.isAuthenticated) {
      this.cartKey = uuidV4();
    }

    await this.getCartItems();
  }

  @action
  async getCartItems() {
    if (!this.cartKey) {
      return;
    }

    const { data } = await this.apiClient.sendRequest(
      'get',
      `api/v1/shopping_carts/get_shopping_cart`,
      {
        query: {
          shopping_cart_key: this.cartKey
        }
      }
    );

    this.items = data.response.shopping_cart_items;
  }

  @action
  async addItemToCart(productKey) {
    try {
      await this.apiClient.sendRequest(
        'post',
        `api/v1/shopping_carts/add_product_to_shopping_cart`,
        {
          body: {
            shopping_cart_key: this.cartKey,
            product_key: productKey
          }
        }
      );

      // await this.getCartItems();

      window.location = '/btmf/cart1';
    } catch (err) {
      if (err.response?.status === 403 || err.response?.status === 400) {
        this.isCartErrorModalOpen = true;
        return;
      }
      throw err;
    }
  }

  @action
  setCurrency(currency) {
    this.currency = currency;
  }

  @action
  closeCartErrorModal = () => {
    this.isCartErrorModalOpen = false;
  };
}
