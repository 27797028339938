import React, { useEffect, useState } from 'react';
import Redirect from '../Redirect';

const LocaleForceRedirect = ({ fallbackLocale, nonLocalePath }) => {
  const [url, setUrl] = useState();
  useEffect(() => {
    let locale =
      window.localStorage.getItem('lang') ||
      window.localStorage.getItem('locale') ||
      fallbackLocale;

    // Normalize locale code if we got it from BTMF site
    if (locale === 'en') {
      locale = process.env.LOCALES.find(l => /^en/.test(l));
    } else if (locale === 'hk') {
      locale = 'zh-hk';
    } else if (locale === 'tw') {
      locale = 'zh-tw';
    } else if (locale === 'cn' || locale === 'zh') {
      locale = 'zh-cn';
    }
    if (process.env.REGION === 'tw') {
      locale = 'zh-tw';
    }

    if (!locale) {
      /* eslint-disable-next-line prefer-destructuring */
      locale = process.env.LOCALES[0];
    }

    setUrl(`/${locale}${nonLocalePath}`);
  }, []);
  return <Redirect to={url} includeQuery replace />;
};

export default LocaleForceRedirect;
