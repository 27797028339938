import axios from 'axios';
import qs from 'qs';

export default class ApiClient {
  baseUrl = '/';

  authHeaders = {};

  response403Handlers = [];

  constructor(baseUrl) {
    this.baseUrl = baseUrl.replace(/\/$/, '');
  }

  async sendRequest(method, url, { headers = {}, query = {}, body } = {}) {
    try {
      return axios({
        method,
        headers: {
          ...this.authHeaders,
          ...headers
        },
        url: `${this.baseUrl}/${url.replace(/^\//, '')}?${qs.stringify(query)}`,
        data: body
      });
    } catch (err) {
      if (err.response?.status === 403) {
        this.response403Handlers.forEach(fn => fn());
      }
      throw err;
    }
  }

  setAuthHeaders(uid, token, client) {
    this.authHeaders = {
      uid,
      'access-token': token,
      client
    };
  }

  addResponse403Listener(fn) {
    if (this.response403Handlers.indexOf(fn) === -1) {
      this.response403Handlers.push(fn);
    }
  }
}
