import React, { createContext, useContext } from 'react';

const ApiContext = createContext({});

export const useApi = () => useContext(ApiContext);

const ApiProvider = ({ apiClient, children }) => {
  return (
    <ApiContext.Provider value={apiClient}>{children}</ApiContext.Provider>
  );
};

export default ApiProvider;
