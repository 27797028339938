import { action, observable } from 'mobx';
import BaseStore from './BaseStore';

/*
 TODO:
  Currently, separate regions are under different domain names, so products only
  need to be fetched once. Once we've combined all regions under the same
  domain, ProductStore needs to be aware of the current locale and re-fetch
  products accordingly.
 */

export default class ProductStore extends BaseStore {
  @observable products = [];

  @action
  async init() {
    await this.getProducts();
  }

  @action
  async getProducts() {
    const {
      data: { response }
    } = await this.apiClient.sendRequest(
      'get',
      `api/v1/products/get_homepage_products`,
      { query: { series_code: 'r2d2' } }
    );

    this.products = response;
  }
}
