export default class BaseStore {
  rootStore;

  apiClient;

  constructor(rootStore, apiClient) {
    this.rootStore = rootStore;
    this.apiClient = apiClient;
  }
}
