export function getLocaleFromPath(path) {
  const match = /^\/([a-zA-Z]{2}-[a-zA-Z]{2})/.exec(path);
  return match && match[1];
}

export function isCurrentLocalePath(currentPath, pathWithoutLocale) {
  const matchPath =
    !pathWithoutLocale || pathWithoutLocale === '/' ? '' : pathWithoutLocale;
  return new RegExp(`^/[a-zA-Z]{2}-[a-zA-Z]{2}${matchPath}$`).test(currentPath);
}
